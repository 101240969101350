<template>
  <vue-scroll class="register-page ps" :settings="settings">
    <div class="form-box card-base card-shadow--large">
      <div class="center">
        <h2 class="accent-text">Edit Profile</h2>
        <p>After editing profile, your code will not change</p>
      </div>
      <el-divider class="mv-10 o-020"></el-divider>
      <div>
        <el-form :label-position="labelPasition" :rules="rules" ref="form" label-width="160px" :model="form">
            <el-form-item label="FIRST NAME" prop="first">
                <el-input size="medium" v-model="form.first" type="text" name="fname" id="frmNameA" autocomplete="given-name"></el-input>
            </el-form-item>
            <el-form-item label="SURNAME" prop="surname">
                <el-input size="medium" v-model="form.surname" type="text" name="lname" id="frmNameA" autocomplete="family-name"></el-input>
            </el-form-item>
            <el-form-item label="EMAIL" prop="email">
                <el-input size="medium" v-model.trim="form.email" type="email" name="email" id="frmEmailA" autocomplete="email" disabled></el-input>
            </el-form-item>
            <el-form-item label="PHONE" prop="phone">
                <el-input size="medium" v-model="form.phone" type="tel" name="phone" id="frmPhoneNumA" autocomplete="tel"></el-input>
            </el-form-item>
            <el-form-item label="BIRTHDAY" prop="birthday">
                <el-date-picker
                  :editable="false"
                  type="date"
                  size="medium"
                  v-model="form.birthday">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="DEPENDENTS">
              <div class="flex column lh-32">
                <div class="pl-16 box flex column" v-if="form.children.length > 0">
                  <div class="box grow flex justify-space-between" v-for="(c, index) in form.children" :key="index">
                    <el-button type="text" @click="onClickEditAppend(index)">
                      <span :class="{'secondary-text' : !form.children[index].isActive}">
                        {{c.first}} {{c.surname}} {{formatDate(c.birthday)}} y.o.
                      </span>
                    </el-button>
                  </div>
                </div>
                <div class="">
                  <el-button type="text" @click="onClickNewAppend"><i class="mdi mdi-account-plus-outline"></i> Add</el-button>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="medium" @click="submit('form')" class="signin-btn">
                SUBMIT
              </el-button>
            </el-form-item>
        </el-form>
      </div>
    </div>
    <el-dialog title="Dependent" :visible.sync="dialogAppendVisible">
      <el-form :label-position="labelPasition" :model="formChild" label-width="160px" :rules="rulesChild" ref="formChild">
            <el-form-item label="NAME" prop="first">
                <el-input size="medium" v-model="formChild.first" type="text" name="name" id="frmNameA" required autocomplete="name"></el-input>
            </el-form-item>
            <!-- <el-form-item label="Surname" prop="surname">
                <el-input v-model="formChild.surname" type="text" name="lname" id="frmNameA" autocomplete="family-name"></el-input>
            </el-form-item> -->
            <el-form-item label="BIRTHDAY" prop="birthday">
                <el-date-picker
                  :editable="false"
                  size="medium"
                  width="100%"
                  type="date"
                  v-model="formChild.birthday">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-switch
                v-model="formChild.isActive"
                active-text="inside"
                inactive-text="outside">
              </el-switch>
            </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogAppendVisible = false">Cancel</el-button>
        <el-button v-if="formChild.index === -1" type="primary" @click="confirmNewAppend('formChild')">Add dependent</el-button>
        <el-button v-else type="primary" @click="confirmEditAppend('formChild', formChild.index)">Edit dependent</el-button>
      </span>
    </el-dialog>
  </vue-scroll>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Edit',

  data () {
    var validateConfirm = (rule, value, callback) => {
      if (value === false) {
        callback(new Error('Please read and accept terms'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        surname: [
          { required: true, message: 'Please input surname', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'Please input email address', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        phone: [
          { required: true, message: 'Please input phone number', trigger: 'blur' }
        ],
        birthday: [
          { required: true, message: 'Please pick a date', trigger: 'change' }
        ],
        confirm: [
          { required: true, message: 'Please read and accept terms', trigger: 'blur' },
          { validator: validateConfirm, trigger: 'blur' }
        ]
      },
      rulesChild: {
        first: [
          { required: true, message: 'Please input first name', trigger: 'blur' }
        ],
        surname: [
        ],
        birthday: [
          { type: 'date', required: true, message: 'Please pick a date', trigger: 'change' }
        ]
      },
      form: {
        first: '',
        surname: '',
        email: '',
        phone: '',
        birthday: '',
        children: []
      },
      formChild: {
        first: '',
        surname: '',
        birthday: new Date(),
        index: -1,
        isActive: true
      },
      dialogAppendVisible: false,
      innerHeight: 0,
      innerWidth: 0,
      settings: {
        maxScrollbarLength: 60
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user
    }),
    // scrollHeight () {
    //   return this.innerHeight
    // },
    labelPasition () {
      return this.innerWidth >= 768 ? 'left' : 'top'
    }
  },

  methods: {
    ...mapActions(['editProfile']),
    formatDate (date) {
      let birthday = +new Date(date)
      return ~~((Date.now() - birthday) / (31557600000))
    },
    confirmNewAppend (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.children.splice(this.form.children.length, 0, this.formChild)
          this.dialogAppendVisible = false
        }
      })
    },
    confirmEditAppend (formName, index) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.form.children.splice(index, 1, this.formChild)
          this.dialogAppendVisible = false
        }
      })
    },
    submit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let model = Object.assign(
            this.form,
            {
              phone: this.form.phone.replaceAll(' ', ''),
              birthday: new Date(this.form.birthday).toISOString().slice(0, 10),
              children: this.form.children.map(c => {
                return {
                  first: c.first,
                  isActive: c.isActive,
                  birthday: new Date(c.birthday).toISOString().slice(0, 10)
                }
              })
            }
          )
          this.editProfile(model)
            .then(() => {
              this.$message({
                showClose: true,
                message: 'Profile edited successfully',
                type: 'success'
              })
            })
            .then(() => {
              this.$onCommandParams('home')
            })
            .catch(err => {
              this.$message({
                showClose: true,
                message: err.message,
                type: 'error'
              })
            })
        }
      })
    },
    onClickEditAppend (index) {
      this.dialogAppendVisible = true
      this.formChild = Object.assign({}, this.form.children[index], {
        index: index, birthday: new Date(this.form.children[index].birthday)
      })
    },
    onClickNewAppend (index) {
      this.dialogAppendVisible = true
      this.formChild = {
        first: '',
        surname: '',
        birthday: new Date(),
        index: -1,
        isActive: true
      }
    },
    resizeOpenNav () {
      this.innerHeight = window.innerHeight
      this.innerWidth = window.innerWidth
    }
  },
  activated () {
    this.$nextTick(() => {
      this.$store.commit('setSplashScreen', false)
    })
  },
  mounted () {
    let children = [...this.user.children]
    this.form = Object.assign({}, {
      birthday: this.user.hasOwnProperty('birthday')
        ? new Date(this.user.birthday)
        : '',
      first: this.user.first,
      surname: this.user.surname,
      email: this.user.email,
      phone: this.user.phone,
      children: children || []
    })
    this.resizeOpenNav()
    window.addEventListener('resize', this.resizeOpenNav)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.resizeOpenNav)
  }
}
</script>
